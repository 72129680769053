































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RelatedAddresses } from '@/interfaces/investor'

@Component({})
export default class RelatedAddressesComponent extends Vue {
  @Prop({ required: true }) items!: RelatedAddresses[]
}

