









































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PreviousLaborData } from '@/interfaces/investor'

@Component({})
export default class PreviousLaborDataComponent extends Vue {
  @Prop({ required: true }) items!: PreviousLaborData[]
}

