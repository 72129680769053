




























import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import Component from 'vue-class-component'
import Model from '@/lib/prest-query-builder'
import { User } from '@/interfaces/user'
import { groupBy } from 'underscore'
import { Prop, Watch } from 'vue-property-decorator'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class UserChart extends Vue {
  @Prop({ required: false }) title!: string
  userModel!: Model<User>
  users: User[] = [] as any

  $moment

  data: { x: string; y: number }[] = []

  series: any[] = [
    { data: this.data }
  ]

  chartOptions = {
    chart: {
      id: 'chart1',
      type: 'line',
      height: 230,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#546E7A', '#546E7A'],
    stroke: {
      width: 3,
      stroke: { curve: 'straight' }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime'
    }
  }

  seriesLine: any[] = [
    { data: this.data }
  ]

  chartOptionsLine = {
    chart: {
      id: 'chart2',
      height: 130,
      type: 'area',
      brush: {
        target: 'chart1',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min: 0,
          max: 0
        }
      }
    },
    toolbar: {
      show: false,
      autoSelected: 'selection'
    },
    colors: ['#008FFB'],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2
    }
  }

  state = {
    loading: false
  }

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  async loadUsers() {
    this.state.loading = true
    this.userModel.where({
      field: 'data_criacao',
      operator: '$gte',
      value: this.$moment().subtract(6, 'months').format('YYYY-MM-DD')
    })
      .order('data_criacao')
      .run()
      .then((users: any) => {
        this.users = users
        const data = this.transformData(users)

        this.series = [{
          name: 'Cadastros',
          data: data
        }]

        this.seriesLine = [{
          name: 'Cadastros',
          data: data
        }]

        const { min, max } = this.getSelection(this.users)

        this.chartOptionsLine = {
          ...this.chartOptionsLine,
          chart: {
            ...this.chartOptionsLine.chart,
            selection: {
              ...this.chartOptionsLine.chart.selection,
              xaxis: {
                min,
                max
              }
            }
          }
        }
      })
      .then(() => {
        this.state.loading = false
      })
  }

  getSelection(users) {
    const format = 'YYYY MM DD'
    const lastDateStr = (users[users.length - 1] as any).data_criacao

    const lastDate = this.$moment(lastDateStr).format(format)
    const firstDate = this.$moment(lastDate).subtract(1, 'months').format(format)

    return {
      min: new Date(firstDate).getTime(),
      max: new Date(lastDate).getTime()
    }
  }

  transformData(users: User[]): { x: string; y: number }[] {
    const groupedByDay = groupBy(users, (user) => {
      const date = this.$moment(user.data_criacao).format('MM-DD-YYYY')
      return date
    })

    const finalFormat: { x: string; y: number }[] = Object.keys(groupedByDay).map(date => {
      return {
        x: date,
        y: groupedByDay[date].length
      }
    })

    return finalFormat
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.userModel = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.loadUsers()
    }
  }
}

