





































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import UserService from '@/services/User.service'
import { Pagination as IPagination } from '@/interfaces/pagination'
import { User, CivilState, Citizenship, Gender } from '@/interfaces/user'
import { ComplementaryData } from '@/interfaces/investor'
import PersonalRelations from '@/components/investor/PersonalRelations.vue'
import PreviousLaborData from '@/components/investor/PreviousLaborData.vue'
import RelatedAddresses from '@/components/investor/RelatedAddresses.vue'
import { updateContact } from '@/services/Functions.service'

import Pagination from '@/components/Pagination.vue'
import { Investment } from '@/interfaces/pRest/investment'
import { mapGetters } from 'vuex'

import Model from '@/lib/prest-query-builder'
import { Watch } from 'vue-property-decorator'

const { VUE_APP_DASHBOARD_API, VUE_APP_API_PREST_NEW } = process.env

interface FilterOptions {
  nome: string | null;
  email: string | null;
  cpf: string | null;
  telefone: string | null;
}

@Component({
  components: {
    Pagination,
    PersonalRelations,
    PreviousLaborData,
    RelatedAddresses
  },
  computed: {
    ...mapGetters({
      userPublicKey: 'auth/publicKey'
    })
  }
})
export default class Users extends Vue {
  private userService = new UserService();

  investmentsModel!: Model<Investment>
  investorsModel!: Model<Investment>

  investor!: any

  CivilState = CivilState
  Citizenship = Citizenship
  Gender = Gender

  user: User = {} as User

  investments: Investment[] = []

  complementaryData: ComplementaryData = {} as ComplementaryData

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  filter: FilterOptions = {
    cpf: null,
    email: null,
    nome: null,
    telefone: null
  }

  state = {
    isFilterOpen: false,
    loading: false,
    error: '',
    updateContact: {
      loading: false
    }
  }

  pagination: IPagination = {} as IPagination

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.investmentsModel = new Model<Investment>({
        url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investimento`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.investorsModel = new Model<Investment>({
        url: `${VUE_APP_API_PREST_NEW}/Investimento/public/investidor`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.loadData()
    }
  }

  async loadData() {
    const email = this.$route.params.email
    this.user = await this.userService.profile(email)

    this.complementaryData = await this.userService.complementaryData(email).then((profile) => profile.dados_complementares)

    this.investor = await this.investorsModel
      .where({
        field: 'email',
        operator: '$eq',
        value: this.$route.params.email
      })
      .run()
      .then((users) => users[0])

    if (!this.investor) return

    this.investmentsModel
      .where({
        field: 'investidor_id',
        operator: '$eq',
        value: this.investor.id
      })
      .run()
      .then((investments: any) => {
        this.investments = investments
      })
  }

  get paidInvestments() {
    return this.investments.filter((i: any) => i.status === 'PAGO')
  }

  get totalInvested() {
    return this.paidInvestments.reduce((acc: number, cur: Investment) => {
      return acc + cur.valor
    }, 0)
  }

  fetchComplementaryData() {
    this.userService.fetchComplementaryData(this.user.cpf)
      .then(() => {
        alert('O pedido de atualização dos dados complementares foi enviado. Dentro de instantes, atualize a página para verificar.')
      })
  }

  successToast(msg: string) {
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: msg,
      group: 'form'
    })
  }

  errorToast(msg: string) {
    this.$notify({
      type: 'error',
      title: 'Ops!',
      text: msg,
      group: 'form'
    })
  }

  async onUpdateContact() {
    try {
      this.state.updateContact.loading = true
      await updateContact(this.user)
      this.successToast('Contato sincronizado com sucesso.')
    } catch (error) {
      this.errorToast('Erro ao sincronizar contato. Contate  o suporte.')
    } finally {
      this.state.updateContact.loading = false
    }
  }
}

