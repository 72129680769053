import { render, staticRenderFns } from "./RelatedAddresses.vue?vue&type=template&id=4c50afe4&scoped=true&"
import script from "./RelatedAddresses.vue?vue&type=script&lang=ts&"
export * from "./RelatedAddresses.vue?vue&type=script&lang=ts&"
import style0 from "./RelatedAddresses.vue?vue&type=style&index=0&id=4c50afe4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c50afe4",
  null
  
)

export default component.exports