




































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PersonalRelations } from '@/interfaces/investor'

@Component({})
export default class PersonalRelationsComponent extends Vue {
  @Prop({ required: true }) items!: PersonalRelations[]
}

