











































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import JsonExcel from 'vue-json-excel'
import { User, UserToCSV } from '@/interfaces/user'
import { mask } from 'vue-the-mask'
import Model, { PageOptions, Pagination as IPagination } from '@/lib/prest-query-builder'
import PrestPagination from '@/components/PrestPagination.vue'
import UsersChart from '@/components/charts/UsersChart.vue'
import { Watch } from 'vue-property-decorator'

const { VUE_APP_API_PREST_NEW, VUE_APP_DASHBOARD_API } = process.env

@Component({
  components: {
    PrestPagination,
    JsonExcel,
    UsersChart
  },
  directives: {
    mask
  }
})
export default class Users extends Vue {
  userModel!: Model<User>

  // Fix lint error.
  UserToCSV = UserToCSV

  users: User[] = []

  pageOptions: PageOptions = {
    page: 1,
    pageSize: 20
  }

  orderOptions = [
    { label: '+ Data de Criação', value: 'data_criacao' },
    { label: '- Data de Criação', value: '-data_criacao' },
    { label: '+ Nome completo', value: 'nome_completo' },
    { label: '- Nome completo', value: '-nome_completo' }
  ]

  selectedOrder = '-data_criacao'

  filter = {
    cpf: {
      type: '$eq',
      value: null
    },

    email: {
      type: '$eq',
      value: null
    },

    nome_completo: {
      type: '$ilike',
      value: null
    },

    telefone: {
      type: '$eq',
      value: null
    }
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  pagination: IPagination = {} as IPagination

  $moment!: any

  get prestIsReady() {
    return !this.$store.getters['prest/loading'] && !!this.$store.getters['prest/authorizationKey']
  }

  get authorizationKey() {
    return this.$store.getters['prest/authorizationKey']
  }

  loadUsers() {
    this.state.loading = true

    this.userModel
      .join({
        type: 'inner',
        tableJoin: 'endereco',
        tableJoinKey: 'id',
        operator: '$eq',
        table: 'cadastro',
        tableKey: 'id_endereco'
      })
      .order(this.selectedOrder)
      .pagination(this.pageOptions)

    for (const f in this.filter) {
      let { value, type } = this.filter[f]
      if (value) {
        if (type.match(/like/)) {
          value = encodeURIComponent(`%${value}%`)
        }

        this.userModel.where({
          field: f,
          operator: type,
          value: value
        })
      }
    }

    this.userModel
      .run()
      .then((response: any) => {
        this.users = response.items
        this.pagination = response.pagination
        this.state.loading = false
      })
  }

  async fetchData() {
    this.userModel
      .join({
        type: 'inner',
        tableJoin: 'endereco',
        tableJoinKey: 'id',
        operator: '$eq',
        table: 'cadastro',
        tableKey: 'id_endereco'
      })
      .order(this.selectedOrder)

    for (const f in this.filter) {
      let { value, type } = this.filter[f]
      if (value) {
        if (type.match(/like/)) {
          value = encodeURIComponent(`%${value}%`)
        }

        this.userModel.where({
          field: f,
          operator: type,
          value: value
        })
      }
    }

    const response = await this.userModel
      .run()
      .catch(() => {
        this.state.downloadLoading = false

        const message = 'Erro ao baixar lista de usuários.'

        this.$notify({
          type: 'error',
          title: 'Ops!',
          text: message,
          group: 'form'
        })
      })

    return response
  }

  onToPage(n) {
    this.$set(this.pageOptions, 'page', n)
    this.loadUsers()
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
    this.$notify({
      type: 'success',
      title: 'Concluído',
      text: 'Download de arquivo concluído.',
      group: 'form'
    })
  }

  onFilterReset() {
    this.filter = {
      cpf: {
        type: '$eq',
        value: null
      },

      email: {
        type: '$eq',
        value: null
      },

      nome_completo: {
        type: '$ilike',
        value: null
      },

      telefone: {
        type: '$eq',
        value: null
      }
    }

    this.loadUsers()
  }

  @Watch('prestIsReady', { immediate: true })
  onPrestIsReadyChange(value) {
    if (value) {
      this.userModel = new Model<User>({
        url: `${VUE_APP_API_PREST_NEW}/Cadastro/public/cadastro`,
        authorization_token: this.authorizationKey,
        proxy: `${VUE_APP_DASHBOARD_API}/prest/proxy`
      })

      this.loadUsers()
    }
  }

  onFilterSubmit() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadUsers()
  }

  @Watch('pageOptions.pageSize')
  onPageOptionsChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadUsers()
  }

  @Watch('selectedOrder')
  onOrderChange() {
    this.$set(this.pageOptions, 'page', 1)
    this.loadUsers()
  }
}

